<template lang="pug">
.layout: .delete-page
  .delete-page__title Удаление аккаунта
  .delete-page__text
    span Жаль, что вы нас покидаете 😔
    span Если вы удалите аккаунт сейчас, то восстановить его уже не получится. Все загруженные  данные и файлы будут удалены безвозратно
  .delete-page__form
    .delete-page__form-title Поделитесь, пожалуйста, с нами причиной удаления аккаунта
    .delete-page__reasons
      q-radio(
        v-model="reason" 
        v-for="item in allReasons" 
        :val="item" 
        :label="item"
        @input="customReason = null")
      q-radio(
        v-model="reason" 
        val="Своя причина" 
        label="Своя причина")
    .form-input.form-input--area(v-if="reason === 'Своя причина'" ): q-input(
      type="textarea" 
      v-model="customReason" 
      placeholder="Опишите причину")
    .delete-page__footer
      BaseBtn(@click="confirmModalActive = true" :iconSize="16" icon="delete-icon" type="error" size="sm") Удалить аккаунт
      span Удачи вам и здоровья 👋 
  MessageModal(v-model="confirmModalActive"
    title="Вы точно хотите удалить аккаунт?"
    rowButtons): template(#buttons)
    BaseBtn(@click="deleteAccount" :iconSize="16" icon="delete-icon" type="error" size="sm") Удалить
    BaseBtn(@click="confirmModalActive = false" type="transparent" size="sm") Отмена
</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import { deletePatient } from '@/api/patient.js';

export default {
  data(){
    return {
      confirmModalActive: false,
      reason: 'Не хочу пользоваться приложением',
      customReason: null,
      allReasons: [
        'Не хочу пользоваться приложением',
        'Боюсь за свои данные',
        'Еще какая-нибудь причина'
      ]
    }
  },
  components: { MessageModal },
  methods: {
    async deleteAccount(){
      await deletePatient({reason: this.customReason || this.reason})
      await this.$metrika.reachGoal('userRemoveAccount')
      this.$store.dispatch('auth/logOut');
      location.reload();
    }
  }
}
</script>

<style lang="scss">
.delete-page {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 615px;
}

.delete-page__title {
  font-size: 26px;
  font-weight: 500;
}

.delete-page__text {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.delete-page__form {
  padding: 40px;
  border-radius: 26px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.delete-page__form-title { 
  font-size: 16px;
}

.delete-page__reasons {
  display: flex;
  flex-direction: column;
}

.delete-page__footer {
  display: flex;
  align-items: center;
  gap: 24px;
}
</style>