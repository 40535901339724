import { render, staticRenderFns } from "./DeleteAccountPage.vue?vue&type=template&id=27ff680f&lang=pug&"
import script from "./DeleteAccountPage.vue?vue&type=script&lang=js&"
export * from "./DeleteAccountPage.vue?vue&type=script&lang=js&"
import style0 from "./DeleteAccountPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QRadio,QInput});
